import { Link } from "gatsby"
import React from "react"
import Layout from "../components/layout"

const NotFoundPage = () => (
  <Layout>
    <div className="page-404">
      <Link to="/">Retour à l'accueil</Link>
    </div>
  </Layout>
)

export default NotFoundPage
